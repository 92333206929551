<template lang="pug">
#need-signature.step-component
  icon(data="~@icon/signing.svg", :fill="false")
  h1 We need your signature

  p By clicking on the "Continue to Signing" button below, you acknowledge your agreement to Kit.com's 1) Informed Consent for Kit Diagnostic Tests and Related Services, 2) Terms of Use, 3) Privacy Notice. In addition, you consent to recording your interactions with the Kit application to validate your identity.

  button.button(@click="goNext()") Continue
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  beforeCreate() {
    // Everything after this step in the BDO flow must be in landscape mode, so don't unset it when
    // the component destroys
    this.$app.forceLandscape = true
  },

  mounted() {},

  methods: {
    goNext,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goNext() {
  this.$emit('complete')
}
</script>
